<template>
  <v-container grid-list-xs class="my-10">
    <v-row class="justify-center">
      <v-col cols="12" md="12" lg="10">
        <!-- <h2 class="font-weight-bold">Método de pago</h2> -->
        <v-row class="justify-center">
          <v-col cols="12" md="6" class="order-1">
            <v-card
              tile
              elevation="10"
              raised
              class="rounded-lg"
              :style="{ backgroundColor: bgColor }"
            >
              <v-card-text class="">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <div class="text-right">
                      <img
                        style="height: 50px"
                        :src="require(`../assets/img/card/${logoCard}`)"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <div class="form-group">
                      <label
                        class="white--text font-weight-bold h5"
                        for="num_card"
                        >Número de la tarjeta</label
                      >

                      <input
                        class="form-control"
                        id="num_card"
                        placeholder="####-####-####-####"
                        type="text"
                        v-model.trim="card.number"
                        max="19"
                        @keypress="validOnlyNmbers"
                        maxlength="19"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <div class="form-group">
                      <label
                        class="white--text font-weight-bold h5"
                        for="date_exp"
                        >Fecha de expiración</label
                      >

                      <input
                        class="form-control"
                        id="date_exp"
                        v-model.trim="card.date_exp"
                        placeholder="mm-yy"
                        @keypress="validOnlyNmbers"
                        max="5"
                        maxlength="5"
                        type="text"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <div class="form-group">
                      <label
                        class="white--text font-weight-bold h5"
                        for="card_cvv"
                        >CVV/CVC</label
                      >

                      <input
                        class="form-control"
                        id="card_cvv"
                        v-model.trim="card.cvc"
                        max="3"
                        min="3"
                        maxlength="3"
                        placeholder="293"
                        type="password"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-col cols="12" class="px-0 mt-4">
              <input
                class="form-control input_card"
                v-model.number="card.name"
                placeholder="Nombre completo (S)"
                type="text"
              />
            </v-col>
            <v-col cols="12" class="px-0 mt-4">
              <input
                class="form-control input_card"
                v-model.trim="card.phone"
                placeholder="Teléfono"
                type="text"
              />
            </v-col>
            <!-- <v-col cols="12">
              <v-checkbox
                label="Agregar método de pago como principal"
                color="primary"
                v-model="defaultCard"
                hide-details
              ></v-checkbox>
            </v-col> -->
            <v-col cols="12">
              <v-alert
                class="mt-2 hidden-md-and-up"
                v-for="(error, idx) in getErrors"
                :key="idx"
                dense
                text
                type="error"
              >
                {{ error }}
              </v-alert>
              <v-btn
                class="hidden-md-and-up"
                @click="payService"
                depressed
                color="primary"
                large
                :loading="isLoadingBtn"
              >
                <span class="font-weight-bold h5 px-4">Realizar pago</span>
              </v-btn>
            </v-col>
          </v-col>
          <v-col cols="12" md="6" class="order-0 py-0">
            <v-card elevation="0" raised color="white lighten-5">
              <v-card-text class="h4 black--text py-0">
                <div class="mb-md-6">
                  <h2 class="font-weight-bold">Resumen de la compra</h2>
                </div>
                <div class="text-decoration-underline">
                  {{ getProductSelected.name }}
                </div>
                <div class="d-flex mt-4 justify-space-between">
                  <p class="black--text">Precio</p>
                  <p class="black--text">
                    {{ getProductSelected.price | formatCurrencyIso }}
                    /
                    {{ getDate }}
                  </p>
                </div>
                <v-divider></v-divider>
                <div class="d-flex mt-4 justify-space-between">
                  <p class="black--text">Total</p>
                  <p class="black--text">
                    {{ getProductSelected.price | formatCurrencyIso }}
                  </p>
                </div>
                <v-alert
                  class="mt-2 hidden-sm-and-down"
                  v-for="(error, idx) in getErrors"
                  :key="idx"
                  dense
                  text
                  type="error"
                >
                  {{ error }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="hidden-sm-and-down"
                  @click="payService"
                  depressed
                  color="primary"
                  large
                  :loading="isLoadingBtn"
                >
                  <span class="font-weight-bold h5 px-4">Realizar pago</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "../components/dashboard/Loading.vue";

import { formatCurrencyIso } from "../filters/currency";
import { mapActions } from "vuex";
export default {
  components: { Loading },
  data() {
    return {
      value: 0,
      card: {
        number: "",
        date_exp: "",
        name: "",
        cvc: "",
        phone: null,
      },
      defaultCard: true,
      typeCard: "MasterCard",
      bgColor: "#1e4f2a",
      logoCard: "logo-mastercard.svg",
      isLoadingBtn: false,
    };
  },
  filters: {
    formatCurrencyIso,
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.getMyMembership();
      // // console.log("anes de entrat");
      // console.log(vm.getMembership);
      if (vm.getMembership) {
        // next({ name: "Mymembership" });
        window.location.href = "/mi-membresia";
      } else {
        next({ name: "MembershipCheckout" });
      }
    });
  },
  mounted() {
    this.setErrors("clear");
    this.card.number = this.formaTNmCard;
    this.card.date_exp = this.formaTDateExpCard;
    const script = document.createElement("script");
    script.src = "https://cdn.conekta.io/js/latest/conekta.js";
    script.async = true;
    document.body.appendChild(script);
    if (this.getUser.phone) {
      this.card.phone = this.getUser.phone;
    } else {
      this.card.phone = "";
    }
    // console.log(this.getUser);
    // console.log(this.getUser.UserBroker);
  },

  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("cart", ["getProductSelected"]),
    ...mapGetters("card", ["getErrors", "getTokenCard"]),
    ...mapGetters("membership", ["getMembership"]),
    getDate() {
      const value = this.getProductSelected.membership.days;
      switch (true) {
        case value <= 30:
          return "1 Mes";
        case value > 30 && value <= 90:
          return "3 Meses";
        case value > 90 && value <= 180:
          return "6 Meses";
        case value > 180 && value <= 365:
          return "1 Año";

        default:
          return "nada";
          break;
      }
    },

    formaTNmCard() {
      if (this.card.number) {
        let cc = this.card.number.split("-").join("");
        cc = cc.match(new RegExp(".{1,4}$|.{1,4}", "g")).join("-");
        return cc;
      }
    },
    formaTDateExpCard() {
      if (this.card.date_exp) {
        let cc = this.card.date_exp.split("-").join("");
        cc = cc.match(new RegExp(".{1,2}$|.{1,2}", "g")).join("-");
        return cc;
      }
    },
  },
  watch: {
    "card.number": function (newValue) {
      // limit de caracteres
      // if (newValue.length > 19) {
      //   this.card.number = this.card.number.slice(0, 19);
      // }
      if (newValue) {
        // seleccionar el tipo de tarjeta
        switch (parseInt(newValue[0])) {
          case 3:
            this.typeCard = "American Express";
            this.bgColor = "#700912";
            this.logoCard = "logo-amex.png";
            break;
          case 4:
            this.typeCard = "Visa";
            this.bgColor = "#14213d";
            this.logoCard = "log-visa.png";
            break;

          default:
            this.typeCard = "MasterCard";
            this.bgColor = "#1e4f2a";
            this.logoCard = "logo-mastercard.svg";
            break;
        }
      }

      // asignar formato
      this.card.number = this.formaTNmCard;
    },
    "card.date_exp": function () {
      this.card.date_exp = this.formaTDateExpCard;
    },
  },
  methods: {
    validOnlyNmbers(event) {
      var key = window.event ? event.which : event.keyCode;
      if (key < 48 || key > 57) {
        event.preventDefault();
        return false;
      }
    },
    async payService() {
      this.setErrors("clear");
      this.isLoadingBtn = true;

      window.Conekta.setPublicKey(process.env.VUE_APP_CONEKTA_PUBLIC_KEY);
      let date_card = "",
        numberCard = "",
        expiryYear = "",
        expiryMonth = "";
      if (this.card.date_exp && this.card.number) {
        // console.log("existen los 2");
        numberCard = parseInt(this.card.number.split("-").join(""));
        date_card = this.card.date_exp.split("-").join("");
        expiryYear = date_card.slice(2, 4);
        expiryMonth = date_card.slice(0, 2);
      } else if (this.card.date_exp) {
        // console.log("existe fecha");
        date_card = this.card.date_exp.split("-").join("");
        expiryYear = date_card.slice(2, 4);
        expiryMonth = date_card.slice(0, 2);
      } else if (this.card.number) {
        // console.log("existe card");
        numberCard = parseInt(this.card.number.split("-").join(""));
      } else {
        // console.log("no existe");
        date_card = "";
        numberCard = "";
        expiryYear = "";
        expiryMonth = "";
      }

      const tokenParams = {
        card: {
          number: numberCard,
          name: this.card.name,
          exp_year: expiryYear,
          exp_month: expiryMonth,
          cvc: parseInt(this.card.cvc),
        },
      };
      // console.log(tokenParams);

      await window.Conekta.Token.create(
        tokenParams,
        async (token) => {
          // console.log(token);
          // console.log("paso validaciones");
          const form = {
            name: this.getUser.full_name,
            email: this.getUser.email,
            phone: this.card.phone,
          };
          // console.log(form);
          if (!this.getUser.UserBroker) {
            // console.log("no tiene toen");
            await this.updateProfile(form).then((resp) => {
              // console.log(resp);
              if (resp && resp.error) {
                this.isLoadingBtn = false;
                this.setErrors(resp.error);
                return;
              }
            });
          }

          const customer_id = this.getUser.UserBroker.conekta;
          const order = await this.createOrder({
            total: this.getProductSelected.price,
            customer_id: this.getUser.id,
            description: this.getProductSelected.name,
            product_id: this.getProductSelected.id,
          });

          this.setOver(true);
          this.isLoadingBtn = false;

          // this.setTokenCard(token.id);
          // agregar tarjeta
          const card_id = await this.addCard({
            token_id: token.id,
            customer_id,
          });

          let resp = null;
          if (this.defaultCard) {
            // actualizar tarjeta primary
            // crear suscripción
            Promise.all([
              await this.changePrimaryCard({ card_id, customer_id }),
              (resp = await this.createSuscription({
                plan_id: this.getProductSelected.plan_id,
                customer_id,
              })),
            ]);
          } else {
            // crear suscripción
            resp = await this.createSuscription({
              plan_id: this.getProductSelected.plan_id,
              customer_id,
            });
          }

          const dateStart = new Date(resp.data.msg.subscription_start * 1000)
          const dateEnd = new Date(resp.data.msg.billing_cycle_end * 1000)

          const data = {
            reference: resp.data.msg.id,
            product_id: this.getProductSelected.id,
            start_date: dateStart.toLocaleString(),
            end_date: dateEnd.toLocaleString(),
          };


          // console.log("data", data);

          if (resp.data.msg.status == "active") {
            console.log("la compra se realizó de forma correcta");
            // console.log("actualizar la referecia de la orden");
            // console.log("actualizar el estado");
            await this.updateOrder({
              id: order.id,
              data,
            });

            window.location.href = "/mi-membresia";
            // actualzar la orden con la fecha
            // console.log("todo ha ido bien");
          } else {
            console.log("denegada");

            // eliminar tarjeta
            // await this.deleteCard(card_id);
            // eliminar order
            await this.deleteOrder(order.id);
            this.setErrors(
              "Ha ocurrido un error, por favor verifique sus datos."
            );
            //
          }

          this.isLoadingBtn = false;
          this.setIsSuccess(true);
          this.setOver(false);
        },
        (error) => {
          this.setErrors(error.message_to_purchaser);
          this.setOver(false);
          this.isLoadingBtn = false;
        }
      );
      // console.log(tokenParams);
    },
    ...mapActions("card", [
      "createTokenCard",
      "setErrors",
      "changePrimaryCard",
      "createSuscription",
      "addCard",
      "updateProfile",
      "deleteCard",
    ]),
    ...mapActions("membership", ["setIsSuccess", "getMyMembership"]),
    ...mapActions(["setOver"]),

    ...mapActions("order", [
      "createOrder",
      "createOrderList",
      "updateOrder",
      "deleteOrder",
    ]),
    // ...mapActions("auth", ["updateProfile"]),
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid red;
} */
input {
  border: 1px solid white;
  background: transparent !important;
  border-radius: 10px !important;
  max-height: 45px;
  /* border-radius: inherit; */
  /* box-shadow: none; */
  min-height: 0;
  color: white;
}
input::placeholder {
  color: white;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: white;
  background: transparent;
}

input::placeholder {
  color: rgba(230, 215, 215, 0.74);
}
.input_card {
  border: 1px solid rgb(39, 24, 24);
  background: white !important;
  border-radius: 10px !important;
  max-height: 45px;
  /* border-radius: inherit; */
  /* box-shadow: none; */
  min-height: 0;
  color: grey;
}

.input_card::placeholder {
  color: grey;
}
.input_card:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: grey;
}
</style>
