<template>
  <v-col cols="12" class="text-center">
    <v-progress-circular
      class=""
      :size="100"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-col>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>